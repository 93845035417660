import React, { useContext, useState } from 'react';
import { CartProduct, calcTotalPrice } from './Cart';
import { BsFillHouseFill } from 'react-icons/bs'
import { CartContext } from '../App';

const Form = ({ address, setAddress, review, reviewed }) => {
    return (
        <form
        className="m-5"
        onSubmit={(e) => {
            e.preventDefault();
            review(!reviewed);
        }}>
            <label htmlFor="name">Name</label><br />
            <input className="bg-gray-100 border-2 rounded-md" required type="text" name="name"></input><br />
            <label htmlFor="email">Email</label><br />
            <input className="bg-gray-100 border-2 rounded-md" required type="text" name="email"></input><br />
            <label htmlFor="phonenumber">Phonenumber</label><br />
            <input className="bg-gray-100 border-2 rounded-md" required type="text" name="phonenumber"></input><br />
            <label htmlFor="address">Address</label><br />
            <input className="bg-gray-100 border-2 rounded-md" required type="text" name="address" id="address" value={address} onChange={e => setAddress(e.target.value)}></input><br />
            <input type="submit" className="rounded-lg bg-gray-400 text-white hover:bg-gray-500 p-1 m-1 transition" ></input>
        </form >
    )
}

const FinalPreview = ({ address }) => {
    const {cart} = useContext(CartContext);

    return (
        <div className="m-5">
            <h2 className="text-2xl">Kiitos tilauksesta!</h2>
            <h5>{address}</h5>
            {cart.map(item => <CartProduct key={item.id} item={item} />)}
            <div className="cartItem">Total price: {calcTotalPrice(cart)}€</div>
            <button 
            className="rounded-lg bg-gray-400 text-white hover:bg-gray-500 p-1 m-1 transition"
            onClick={() => window.location.replace(process.env.REACT_APP_ROOT)}>Home</button>
        </div>
    )
}

const FormPage = ({ verify }) => {
    const {cart} = useContext(CartContext);
    const [reviewed, review] = useState(true);
    const [address, setAddress] = useState("");

    return (
        <div>
            <button
            className="rounded-lg bg-gray-400 text-white hover:bg-gray-500 p-2 m-1 transition"
            onClick={() => verify()}><BsFillHouseFill /></button>
            {reviewed ? <Form reviewed={reviewed} review={review} address={address} setAddress={setAddress} /> : <FinalPreview cart={cart} address={address} price={calcTotalPrice(cart)} />}
        </div>
    )
}

export { FormPage, Form };