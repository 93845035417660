import React, { useContext } from 'react'
import {CartContext} from '../App'


const CartProduct = ({ item }) => {
    return (
        <div className="p-1 m-1 rounded-xl border-2">
            <h3>{item.name}</h3>
            <p>{item.price}€</p>
            <p>{item.amount} pieces</p>
        </div>
    )
}

const calcTotalPrice = (cart) => {
    const prices = cart.map(item => (item.price * item.amount));
    return prices.reduce((a, b) => a + b, 0);
}

const Cart = ({ verify }) => {
    const {cart, setCart} = useContext(CartContext);

    return (
        <div className="m-2 shadow-xl rounded-xl border-2 p-2 w-full lg:w-1/4">
            <h2 className="text-2xl bold" >Shoppingcart</h2>
            <h3>Total price: {calcTotalPrice(cart)}€</h3>
            <button 
            className="rounded-xl bg-gray-400 text-white hover:bg-gray-500 p-2 transition"
            onClick={() => setCart([])}>Clear</button>
            <div>
                {cart.map(item => <CartProduct item={item} key={item.id} />)}
            </div>
            <button
            className="rounded-xl bg-gray-400 text-white hover:bg-gray-500 p-2 transition"
            onClick={() => verify()}>Verify order</button>
        </div>
    )
}

export { Cart, CartProduct, calcTotalPrice };