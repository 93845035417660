import React, { useState, createContext } from 'react';
import products_data from './tuotteet.json';
import Products from './components/Products';
import { Cart } from './components/Cart';
import { FormPage } from './components/Form';

export const CartContext = createContext({});

function App() {
  const [cart, setCart] = useState([]);
  const [displayOrderForm, setDisplayOrderForm] = useState(false);

  const verify = () => {
    setDisplayOrderForm(!displayOrderForm);
  }

  return (
    <CartContext.Provider value={{cart: cart, setCart: setCart}}>
    <div className="flex flex-wrap">
        {displayOrderForm ? <FormPage verify={verify} /> : <Products data={products_data} />}
        {displayOrderForm ? undefined : <Cart verify={verify} />}
    </div>
    </CartContext.Provider>
  );
}

export default App;
