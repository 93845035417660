import React, { useContext, useState } from 'react';
import { BsCart } from "react-icons/bs";
import { CartContext } from '../App'

const Product = ({ product, add }) => {
    const [amount, setAmount] = useState(1);
    return (
        <div className="m-2 shadow-xl rounded-xl border-2 py-4 px-2 h-72">
            <p>{product.name}</p>
            <img className="h-40 rounded-xl" src={(process.env.REACT_APP_IMGURI + product.img)} alt={product.img} />
            <p>{product.price}€</p>
            <form className="flex flex-nowrap" onSubmit={e => e.preventDefault()}>
                <input className="bg-gray-100 border-2 rounded-md focused:border-green-300" type="number" value={amount} onChange={e => setAmount(e.target.value)}></input>
                <button className="p-2 border-2 rounded-md" onClick={() => add(product.id, product.name, product.price, amount)}><BsCart /></button>
            </form>
        </div>
    )
}

const Products = ({ data }) => {
    const {cart, setCart} = useContext(CartContext);

    const addProductToCart = (id, name, price, amount) => {
        let updated = false;
        let tempCart = cart.map(p => {
            if (id === p.id) {
                updated = true;
                return { ...p, amount: Number(p.amount) + Number(amount) };
            } else return p;
        }
        )

        if (!updated) tempCart = cart.concat({ id: id, name: name, price: price, amount: amount });
        setCart(tempCart);
    };

    return (
        <div className="flex overflow-x-auto">
            {data.map(p => <Product product={p} key={p.id} add={addProductToCart} />)}
        </div>
    )
}

export default Products;